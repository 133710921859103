import React from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';

const mock = [
  {
    
    description: 'Congratulations to both of you, the teachers and staff at NPS YPR for the excellent results in the 12th standard board exams!So glad to know that so many of your students have done very well. Thank you for your support and dedication towards your students all these years.',
    title: 'Arun and Renuka Irde',
    author: {
      name: 'Parents of Ashwin Irde',
      avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
    },
    date: 'Class 12 B',
  },
  {
    
    description: "This is regarding the event ' GOONJ ' going to happen tomorrow on 22nd July, at your school premises. And we are the proud parents of Ritaprabha Sil, Class 9A, participating in the Dance event and very pleased to see that our son is involved so much with this, practicing, engaging himself with his friends, collaborating with teachers and other classmates, sharing his experience with us and finally enjoying it thoroughly. These are the wonderful moments in everyone's life, Ritaprabha will cherish these days throughout his life, like we did and feel nostalgic about our school days. A small request, hopefully the entire event will be video recorded and we love to see the recorded version whenever it's available for all. Finally a note of thanks to all the teaching fraternity to organise such memorable events at NPS YPR.",
    title: 'Indrajit Sil and Sucheta Sil',
    author: {
      name: '',
      avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
    },
    date: '',
  },
  {
    
    description: 'The graduation ceremony was beautifully organised and timing and management of the event was perfect. It was a wonderful experience for both, the parents and the Students. The dinner was good. We had a pleasant time interacting with the teachers. Overall the ceremony was a grand success. It will be cherished forever by us.',
    title: 'K Hanumantha Rao',
    author: {
      name: 'Parent of Sai Srujana K R',
      avatar: 'https://assets.maccarianagency.com/avatars/img4.jpg',
    },
    date: 'Class 12 A',
  },
  {
   
    description: 'It gives us immense pleasure to formally thank you for organising the Graduation day.For us, this was a completely new experience. The way the entire programme was organised, including the talks from the honourable Chief Guest, the honourable Chairman and Principal, was meticulous. Each word delivered during the course of the event resonated with our thoughts and memories of this journey with our daughter and the school.I am particularly privileged to have got an opportunity to speak, on behalf of the parents community. We wish you the best and hope that you will continue this tradition for years to come.',
    title: 'Venkat Ramann and Sowmya Venkat Ramann',
    author: {
      name: 'Parents of Aditi V ',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: ' V (XII B)',
  },
  {
   
    description: 'This has reference to the recent Graduation day programme organized by your team. I would like to place on record that the programme was well - organized and orchestred. High appreciation to the flow of the programme and the ambience created. The Pictorial presentation of the Down Memory Lane was very well laid out. Full appreciation is extended to the team behind this work.We were able to see that every minute details we taken care well. The dinner arrangement was also good.Finally Congratulations to the complete team behind this program.',
    title: 'Satheesh ',
    author: {
      name: 'f/o S Kaushik',
      
    },
    date: '',
  },
  {
    
    description:
      'Graduation Ceremony was planned and executed well. Proceedings were well managed and the flow of events was seamless.All speakers were focused on the purpose of the evening and were highly inspiring. Outgoing 3rd batch of NPS- YPR were treated to an excellent recognition programme by inviting each student to the stage and awarding mementos to them. Overall a memorable event and an evening to remember for a long time to come.',
    title: 'Sumana Rajaram Kamath',
    author: {
      name: 'Parent of Ranjana R Kamath ',
      avatar: 'https://assets.maccarianagency.com/avatars/img1.jpg',
    },
    date: 'XII A',
  },
  {
  
    description: '"We would like to congratulate the graduating class X students , the teaching staff, the support staff and the school for the commendable result of the first CBSE X batch. Special appreciation to the Principal for leading the team so well to reach the milestone.We are happy for the school and we look forward to many more accolades in the coming years."',
    title: 'Ravikumar Shankaran',
    author: {
      name: ' ',
      
    },
    date: '',
  },
  {
   
    description:
      '"My kids had a wonderful time learning in your institution. They learnt a lot and had fun too and they are always eager to go school. We have been lucky to have a school like this in our neighbourhood. Thank you for all your help and support."',
    title: 'Shantha Pathak',
    author: {
      name: 'Parent of Shreyaan Pathak and Seona Pathak ',
      avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
    },
    date: ' 4A & Mont.I',
  },

  {
   
    description:
      'My daughter has a story to tell me every day about her teachers in the class as to how they correct the students in the class and appreciate their every movement and play with them. I am happy to notice that my daughter has enhanced her dancing skills and enjoy seeing her anxiety for the in-house programme. I can also find that she is comfortablein spite of her friends getting shuffled in different sections and is responding well to her new teachers in KG II. All these achievements are due to the planned effort of the teachers and I sincerely like to appreciate with all respect, the teachers at NPS, YPR.',
    title: 'Savitha',
    author: {
      name: '    Parent of Moksha',
      avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
    },
    date: ' KG II ',
  },

  {
   
    description:
      'We are extremely happy with the progress made by our son since he has been in NPS. The teachers have been very kind and supportive and our son looks forward to going to school every day. We are impressed with the systematic method of teaching and the focus on values such as discipline, honesty, respect, neatness, etc which go a long way in shaping the child"s overall personality. We are happy to be part of the NPS family and convey our best wishes to NPS for scaling greater heights.',
    title: 'Vidhya Prasanna',
    author: {
      name: '    Parent of Vinay Prasanna ',
      avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
    },
    date: ' KG II B',
  },

  {
   
    description:
      'Our son is a proud student of this esteemed Institution. We are grateful to the school for bringing out his leadership and academic capabilities and making avenues for him to recall. The school not only focuses on academics but on the overall development and personality of its students. We are satisfied with the responsibility taken by the authorities with regard to the safety perspective. The teachers are putting in extra effort, time and focus on subjects in which the student might need more attention. We took the right decision by putting our son here.',
    title: 'Krishna Kumar',
    author: {
      name: 'Parents of Hemant ',
      avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
    },
    date: 'IX A',
  },
  {

      description: 'A warm and nurturing school, NPS has provided a great deal of confidence and learning in my child. When we were invited for observation of class, we were glad to see how involved and engrossed each child was with the materials. It has truly encouraged the cognitive, social and physical development in my child. We are so proud to be a part of NPS family.',
    title: 'Gaurav R Bagadia',
    author: {
      name: 'Parent of Naman G Bagadia Montessori ',
      
    },
    date: 'II B',
  },
  {

    description: 'We asked our son what he likes about his school. He said "the play area, the physical education, drawing, painting, the fun with teachers, dictation, Hindi ". We are happy that he is getting an all round development, along with the necessary social skills which form strong foundation in todays world. Most importantly he is having FUN and looks forward to school every day.',
  title: 'Ravikumar Shankaran',
  author: {
    name: ' Parent of Agastya Ravikumar ',
    
  },
  date: 'KG II C',
},
{

description: 'Considering that it was the first sports day for the school, the execution was perfect. It was a delight to see all the students participate. The way the entire show was presented on both the days, clearly showed how much effort was put in by the teachers and how much detailing was taken care of. Right from the beginning till the handing over of the children it was so well taken care of. Thank you for a wonderful show.',
title: 'Dr. Hema T Chaturvedi',
author: {
  name: '',
  
},
date: '',
},

{

  description: 'This is to intimate that we parents of Abhay K studying in 12B thoroughly enjoyed the graduation day conducted on 19 January 2019 in your esteemed premises.The function was organised in a structured fashion and went on very smoothly . we saw all students including our son walking in a procession in front of us in such a dignified style with the relevant background music put on. The compering was of highest quality, doing the justice to the solemn occasion as it highlighted the potential of each student. The behaviour of all students were exceeding professional standards .Kudos to the entire teaching and non teaching faculty of NPS Yeshwantpur for conducting entire function in a befitting manner . Special Thanks to the class teacher Sheeja Madam for guiding our son to reach this stage in his path. Behind all this , there is our principal Ms Alka madam who not only helped our son to reach this stage but also helped us the parents to way ahead in guiding our ward further . Thanks once again for providing us the opportunity to participate in the graduation day function of our son and also followed by the delicious dinner . Waiting for the photograph our son in graduation robe',
  title: 'Parents of Abhay K',
  author: {
    name: '',
    
  },
  date: '',
  },

  {

    description: 'I was particularly looking at our kids perform with so much ease and happiness. They exhibit the confidence which I feel I could attain only during my professional career. They have practically removed the phrase "Stage fright" from their dictionary. - Seeing Shikhar 2014, I believe that we have a generation who is ready to live the moment, grab the opportunity, to not only achieve but to set the bar higher and higher for themselves.- Special mention to the performers of Egyptian Pyramid as well as the team which created the Chariot, the formations really showed the mettle with which our kids are made up of.- Kudos for the entire team of NPS YPR. The punctuality, the attention to detail, the seating arrangements, the traffic co-ordination, the security of the children and even making those small corrections from day1 to day2 were simply great!!',
    title: 'Ravikumar Shankaran',
    author: {
      name: '',
      
    },
    date: '',
    },

    {

      description: 'We were initially apprehensive about changing our children to a new school, especially our daughter because she was in higher class and we were afraid that she would not be able to get comfortable with the new environment. Now after a year we are happy to say this, this is one of the best decisions we have taken for our children. The guidance and the motivation of the teachers and the Principal have impacted them in a positive way. They not only enjoy their interactions with the teachers but also with their fellow students in the school and even in the school bus.',
      title: 'Narayan K Naik and Dr Vedavathy Nayak',
      author: {
        name: 'Parent of Namitha Nayak VII B and Navneet Nayak',
        
      },
      date: 'IV C',
      },

];

const Form = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              href={''}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                <CardMedia
                >
                  <Box
                    component={'svg'}
                    viewBox="0 0 2880 480"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      color: theme.palette.background.paper,
                      transform: 'scale(2)',
                      height: 'auto',
                      width: 1,
                      transformOrigin: 'top center',
                    }}
                  >
                   
                  </Box>
                </CardMedia>
                <Box component={CardContent} position={'relative'}>
                  <Typography variant={'h6'} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary" align ='justify'>
                    {item.description}
                  </Typography>
                </Box>
                <Box  />
                <Box padding={2} display={'flex'} flexDirection={'column'}>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                    
                      <Typography color={'text.secondary'}>
                        {item.author.name}
                      </Typography>
                    </Box>
                    <Typography color={'text.secondary'}>
                      {item.date}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
       
      </Grid>
    </Box>
  );
};

export default Form;
