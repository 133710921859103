import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import { Typography } from '@mui/material';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const StudentsTestimonial= () => {
 

  return (
    <Main>
          <Container maxWidth={0.9} paddingTop={'0 !important'}>
          <Typography align='center' variant='h4'>
            Testimonial
          </Typography>
        </Container>
        
       
        <Container maxWidth={0.9} paddingTop={'0 !important'}>
          <Form />
        </Container>
      
    </Main>
  );
};

export default StudentsTestimonial;
